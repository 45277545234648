import { mapState, mapActions } from 'vuex';
import constant from '~/const';

export default {
  data() {
    return {
      editingFees: {
        show: false,
        userId: 0,
        data: [],
      },
    };
  },

  computed: {
    ...mapState('tradersFees', ['personalDataRecord']),
    ...mapState('tradersCurrencies', {
      tradersCurrencies: 'data',
    }),
    showMerchantFees() {
      return this.rolesValue?.includes(constant.users.USER_ROLE.MERCHANT);
    },
    showTraderFees() {
      return this.rolesValue?.includes(constant.users.USER_ROLE.TRADER);
    },
  },

  methods: {
    ...mapActions('common', [
      'setGeneralProgress',
      'setSuccessNotification',
      'setErrorNotification',
      'confirmAction',
    ]),
    ...mapActions('tradersFees', [
      'editPersonalFees',
      'loadPersonalFeeDataById',
    ]),

    openEditPersonalFeesModal(currentUserPersonalFees, userId) {
      this.editingFees.show = true;
      this.editingFees.userId = userId;
      this.editingFees.data = currentUserPersonalFees;
    },

    applyEditPersonalFees({ input }) {
      this.confirmAction({
        title: `Are you sure you want to update fees of user with id ${this.editingFees.userId}?`,
        callback: async () => {
          this.setGeneralProgress(true);
          this.loader = true;
          try {
            await this.editPersonalFees({ userId: [`${this.editingFees.userId}`], ...input });
            // this.editingFees.show = false;
            await this.loadPersonalFeeDataById({ userId: this.editingFees.userId });
            this.editingFees.data = this.personalDataRecord?.[this.editingFees.userId] ?? [];
            this.setSuccessNotification(`Personal fees of user with id ${this.editingFees.userId} is edited`);
          } catch (error) {
            this.setErrorNotification(error.message);
          }
          this.setGeneralProgress(false);
          this.loader = false;
        },
      });
    },
  },
};
