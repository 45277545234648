//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import constant from '~/const';

export default {
  props: {
    value: { type: Boolean, default: false },
    showMerchantFees: { type: Boolean, default: false },
    showTraderFees: { type: Boolean, default: false },
    data: { type: Array, default: () => ([]) },
    currencies: { type: Array, default: () => ([]) },
  },

  data() {
    return {
      input: {},
      constant,
    };
  },

  computed: {
    model: {
      get() {
        const { value } = this;
        return value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
    currencyId() {
      return this.input.currencyId ?? 0;
    },
    isApplyPossible() {
      if (this.showMerchantFees) {
        return this.input.sellFeePercent !== '' && this.input.buyFeePercent !== '' && this.input.currencyId;
      }
      if (this.showTraderFees) {
        return this.input.sellProfitPercent !== '' && this.input.buyProfitPercent !== '' && this.input.currencyId;
      }
      return false;
    },
  },

  watch: {
    value(val) {
      if (val) {
        this.input = {};
      }
    },
    currencyId(val) {
      const currentSettings = this.data.find((e) => e.currencyId === val);
      if (this.showMerchantFees) {
        this.$set(this.input, 'sellFeePercent', currentSettings?.sellFeePercent ?? 0);
        this.$set(this.input, 'buyFeePercent', currentSettings?.buyFeePercent ?? 0);
      }
      if (this.showTraderFees) {
        this.$set(this.input, 'sellProfitPercent', currentSettings?.sellProfitPercent ?? 0);
        this.$set(this.input, 'buyProfitPercent', currentSettings?.buyProfitPercent ?? 0);
      }
    },
  },

  methods: {
    apply() {
      const { input } = this;
      this.$emit('apply', { input });
    },
  },
};
