//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex';
import Tag from '~/components/tags/Tag.vue';

export default {
  components: {
    Tag,
  },

  computed: {
    ...mapState('users', ['userTagsList']),
  },

  methods: {
    add(tagId) {
      this.$emit('add', tagId);
    },
  },
};
