//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    list: { type: Array, default: () => [] },
  },

  data() {
    return {
      modal: false,
    };
  },

  computed: {
    UTMListFull() {
      return this.list.map((utm) => {
        const { hostname, search } = new URL(utm.urlString);
        return {
          url: hostname,
          tags: search.slice(1, search.length),
          received: this.$utils.getFullDate(utm.received),
        };
      });
    },

    UTMListNotFull() {
      return this.UTMListFull.slice(0, 3);
    },
  },

  methods: {
    showMore() {
      this.modal = !this.modal;
    },
  },
};
