//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import constant from '~/const';

export default {
  props: {
    list: { type: Array, default: () => [] },
  },

  data() {
    return {
      modal: false,
    };
  },

  computed: {
    nfaList() {
      return this.list.map((nfa, id) => {
        return {
          id,
          name: this.getNfaServiceTypeCaption(nfa.nfaServiceType),
          dateEnabled: nfa.dateEnabled ? this.$utils.getDate(nfa.dateEnabled) : null,
          dateDisabled: nfa.dateDisabled ? this.$utils.getDate(nfa.dateDisabled) : null,
        };
      });
    },

    nfaListNotFull() {
      return this.nfaList.slice(0, 3);
    },
  },

  methods: {
    getNfaServiceTypeCaption(nfaServiceType) {
      const { NFA_SERVICE_TYPE_LIST } = constant.users;
      const result = NFA_SERVICE_TYPE_LIST.find((e) => e.id === nfaServiceType);
      if (result) {
        return this.$t(`const.${result.caption}`);
      }
      return '';
    },

    showMore() {
      this.modal = !this.modal;
    },
  },
};
